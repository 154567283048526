.layout-header {
    height: 13.525rem;
    background: url('./img/header_bg.png') no-repeat;
    background-size: 100% 100%;
    position: relative;
    margin-bottom: 1.3125rem;

    .header-logo {
        width: 1.9625rem;
        height: .5rem;
        position: absolute;
        top: .2875rem;
        left: 1.9875rem;
        background: url('./img/logo.svg') no-repeat;
        background-size: 100% 100%;
    }

    .header-slogan {
        position: absolute;
        top: 4.575rem;
        left: 3rem;

        .slogan-h1 {
            width: 4.4rem;
            height: 2.375rem;
            font-size: .8875rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #FFFFFF;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-bottom: .5625rem;
        }

        .slogan-h2 {
            font-size: .5375rem;
            font-family: Swis721 LtEx BT;
            font-weight: 300;
            margin-bottom: .375rem;
        }

        .slogan-h3 {
            font-size: .5375rem;
            font-family: zihun35hao;
            font-weight: normal;
            color: #FFFFFF;
        }
    }
}

.layout-content {
    .content-one {
        text-align: center;

        .content-title {
            margin-bottom: 1.2125rem;

            .content-title-h1,
            .content-title-h2 {
                font-size: .6rem;
                font-family: Source Han Sans CN;
                margin-bottom: .6375rem;
            }

            .content-title-h2 {
                font-size: .375rem;
                font-weight: bold;
                margin-bottom: 0;
            }
        }

        .content-show {
            height: 5.5rem;
            position: relative;
            margin-bottom: 3.625rem;

            .content-show-one,
            .content-show-two,
            .content-show-three,
            .content-show-four {
                width: 4.15rem;
                height: 5.5rem;
                display: inline-block;
                background: url('./img/product1.png ') no-repeat;
                background-size: 100% 100%;
                position: absolute;
                top: 0;
                left: 4.2875rem;
                text-align: left;

                .icon {
                    width: .725rem;
                    height: .7125rem;
                    background: url('./img/dianli-icon.png ') no-repeat;
                    background-size: 100% 100%;
                    margin: .85rem 0 0 .925rem;
                }

                .h1 {
                    font-size: .3rem;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    font-style: italic;
                    margin: .55rem 0 .475rem .5125rem;
                }

                .h2 {
                    font-size: .2rem;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-style: italic;
                    color: #FFFFFF;
                    margin-left: .625rem;
                }
            }

            .content-show-two {
                background: url('./img/product2.png ') no-repeat;
                background-size: 100% 100%;
                position: absolute;
                top: 0;
                left: 8.0875rem;

                .icon {
                    width: .7375rem;
                    height: .8875rem;
                    background: url('./img/city-icon.png ') no-repeat;
                    background-size: 100% 100%;
                    margin: .85rem 0 0 .925rem;
                }

                .h2 {
                    margin-left: .575rem;
                }
            }

            .content-show-three {
                background: url('./img/product3.png ') no-repeat;
                background-size: 100% 100%;
                position: absolute;
                top: 0;
                left: 11.875rem;

                .icon {
                    width: .675rem;
                    height: .8125rem;
                    background: url('./img/nye-icon.png ') no-repeat;
                    background-size: 100% 100%;
                }

                .h2 {
                    margin-left: .575rem;
                }
            }

            .content-show-four {
                background: url('./img/product4.png ') no-repeat;
                background-size: 100% 100%;
                position: absolute;
                top: 0;
                left: 15.6625rem;

                .icon {
                    width: .6rem;
                    height: .8rem;
                    background: url('./img/yuanqu-icon.png') no-repeat;
                    background-size: 100% 100%;
                }

                .h2 {
                    margin-left: .6125rem;
                }
            }
        }

    }

    .content-two {
        position: relative;

        .line-left,
        .line-right {
            width: 1.4375rem;
            height: 1px;
            background: #FFFFFF;
            position: absolute;
            top: .1875rem;
            left: 8.875rem;
        }

        .line-right {
            left: 13.8375rem;
        }

        .content-title {
            height: 1.225rem;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-bottom: .9875rem;

            .h1,
            .h2 {
                font-size: .375rem;
                font-family: Source Han Sans CN;
                font-weight: bold;
            }

            .h2 {
                font-size: .3rem;
                font-weight: 400;

            }
        }



        .content-show {
            height: 13.5rem;
            background: url('./img/two-bg.png') no-repeat;
            background-size: 100% 100%;
            margin-bottom: 2.6125rem;
            text-align: center;
            overflow: hidden;

            .content-icon {
                display: flex;
                justify-content: center;

                .icon-warping {
                    margin-top: .4rem;
                    display: grid;
                    grid-auto-flow: column;
                    gap: 2.125rem;

                    .icon-one,
                    .icon-two,
                    .icon-three,
                    .icon-four {
                        .img {
                            width: 1.8375rem;
                            height: 2.1rem;
                            background: url('./img/icon1.png') no-repeat;
                            background-size: 100% 100%;
                            margin: auto;
                        }

                        .icon-des {
                            .title-h1 {
                                font-size: .25rem;
                                font-family: Source Han Sans CN;
                                font-weight: 400;
                                margin: 30px 0 .2625rem 0;
                            }

                            .title-h2 {
                                display: grid;
                                grid-auto-flow: row;
                                gap: .2375rem;
                            }
                        }
                    }

                    .icon-two {
                        .img {
                            background: url('./img/icon2.png') no-repeat;
                            background-size: 100% 100%;

                        }
                    }

                    .icon-three {
                        .img {

                            background: url('./img/icon3.png') no-repeat;
                            background-size: 100% 100%;
                        }
                    }

                    .icon-four {
                        .img {
                            background: url('./img/icon4.png') no-repeat;
                            background-size: 100% 100%;
                        }
                    }
                }
            }

            .title-h1 {
                text-align: center;
                font-size: .6rem;
                font-family: Source Han Sans CN;
                font-weight: bold;
                margin-top: 3.375rem;

                .h2 {
                    font-size: .3rem;
                    font-weight: 400;
                    margin-top: .6625rem;

                    .br {
                        margin-top: .25rem;
                    }
                }
            }


        }
    }

    .content-three {
        position: relative;

        .line-left,
        .line-right {
            width: 1.4375rem;
            height: 1px;
            background: #FFFFFF;
            position: absolute;
            top: .1875rem;
            left: 8.875rem;
        }

        .line-right {
            left: 13.8375rem;
        }

        .content-title {
            height: 1.225rem;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-bottom: 1.1125rem;

            .h1,
            .h2 {
                font-size: .375rem;
                font-family: Source Han Sans CN;
                font-weight: bold;
            }

            .h2 {
                font-size: .3rem;
                font-weight: 400;

            }
        }

        .content-show {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: .3rem;

            .content-show-warping {
                display: grid;
                grid-auto-flow: row;
                grid-template-columns: 7.5125rem 7.5125rem;
                grid-template-rows: 3.225rem 3.225rem;
                gap: .225rem;

                .show-one-img,
                .show-two-img,
                .show-three-img,
                .show-four-img {
                    width: 7.5125rem;
                    height: 3.225rem;
                    background: url('./img/volt.png') no-repeat;
                    background-size: 100% 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .title {
                        font-size: .375rem;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #FFFFFF;
                        text-align: center;

                        .h2 {
                            margin-top: .325rem;
                            font-size: .2rem;
                            font-weight: 600;
                        }
                    }
                }

                .show-two-img {
                    background: url('./img/yuanqu.png') no-repeat;
                    background-size: 100% 100%;
                }

                .show-three-img {
                    background: url('./img/nye.png') no-repeat;
                    background-size: 100% 100%;
                }

                .show-four-img {
                    background: url('./img/city.png') no-repeat;
                    background-size: 100% 100%;
                }
            }



        }

        .footer-about {
            height: 11.25rem;
            background: url('./img/four-bg.png') no-repeat;
            background-size: 100% 100%;
            margin-bottom: 2.475rem;
            text-align: center;
            overflow: hidden;
            position: relative;

            .line-one,
            .line-two {
                width: 1.4375rem;
                height: .0125rem;
                background-color: #FFFFFF;
                position: absolute;
                top: 3.2625rem;
                left: 8.7625rem;
            }

            .line-two {
                left: 13.7375rem;
            }

            .h1 {
                font-size: .375rem;
                font-family: Source Han Sans CN;
                font-weight: bold;
                margin-bottom: .5125rem;
                margin-top: 3.075rem;
            }

            .h2-des {

                font-size: .3rem;
                font-family: Source Han Sans CN;
                font-weight: 400;
                margin-bottom: 113px;
                overflow: hidden;

                .br {
                    margin-top: .25rem;
                }

            }

            .number {
                display: flex;
                justify-content: center;

                .number-content {
                    display: grid;
                    grid-auto-flow: column;
                    gap: 1.4375rem;

                    .article {
                        .num {
                            font-size: .55rem;
                            font-family: Source Han Sans CN;
                            font-weight: bold;
                            color: #148FF9;
                        }

                        .des {
                            font-size: .175rem;
                            font-family: Source Han Sans CN;
                            font-weight: 400;
                            color: #148FF9;
                            margin-top: .3125rem;
                        }
                    }
                }
            }
        }

    }

    .content-four {
        position: relative;

        .line-left,
        .line-right {
            width: 1.4375rem;
            height: 1px;
            background: #FFFFFF;
            position: absolute;
            top: .1875rem;
            left: 8.875rem;
        }

        .line-right {
            left: 13.8375rem;
        }

        .content-title {
            height: 1.225rem;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-bottom: 1.425rem;

            .h1,
            .h2 {
                font-size: .375rem;
                font-family: Source Han Sans CN;
                font-weight: bold;
            }

            .h2 {
                font-size: .3rem;
                font-weight: 400;
            }
        }

        .content-show {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 2.5625rem;

            .content-show-bg {
                width: 18.2rem;
                border-radius: .5rem;
                background-color: rgba(20, 143, 249, 0.33);
                margin: 0 auto;
                padding: .825rem 1.1rem 0 .6875rem;
                display: flex;
                flex-wrap: wrap;
                align-items: center;

                .list-item {
                    padding-bottom: .6875rem;
                }

                .flag-icon-one {
                    padding-right: 1.075rem;

                    img {
                        width: 2.4625rem;
                        height: .7125rem;
                    }

                }

                .flag-icon-two {
                    padding-right: 1rem;

                    img {
                        width: 2.425rem;
                        height: .8125rem;
                    }
                }

                .flag-icon-three {
                    padding-right: .875rem;

                    img {
                        width: 2.5875rem;
                        height: .7375rem;
                    }
                }

                .flag-icon-four {
                    padding-right: .875rem;

                    img {
                        width: 2.425rem;
                        height: .6rem;
                    }
                }

                .flag-icon-five {
                    img {
                        width: 2.425rem;
                        height: .8625rem;

                    }
                }

                .flag-icon-six {
                    padding-right: 1.075rem;

                    img {
                        width: 2.5625rem;
                        height: .5125rem;
                    }
                }

                .flag-icon-seven {
                    padding-right: 1rem;

                    img {

                        width: 2.5125rem;
                        height: .8375rem;
                    }
                }

                .flag-icon-eight {
                    padding-right: .875rem;

                    img {
                        width: 2.4625rem;
                        height: .7625rem;

                    }
                }



                .flag-icon-nine {
                    padding-right: .875rem;

                    img {
                        width: 2.5rem;
                        height: .4625rem;
                    }
                }

                .flag-icon-ten {
                    img {
                        width: 2.5125rem;
                        height: .5625rem;
                    }
                }
            }
        }
    }

    .content-end {
        height: 3.7rem;
        background: #4C80E7;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        .left-flag {
            width: 3.475rem;
            height: 2.7875rem;
            background: url('./img/fooetr-top-icon.png') no-repeat;
            background-size: 100% 100%;
            margin-right: 2.1375rem;
        }

        .right-title {
            font-size: .45rem;
            font-family: Adobe Heiti Std;
            font-weight: normal;

        }
    }



}

.footer {
    height: 7.125rem;
    background-color: rgba(14, 13, 18, 1);

    .content-list {
        height: 5.6rem;
        display: flex;
        justify-content: center;
        align-items: center;

        .content-list-warp {
            display: grid;
            grid-auto-flow: column;
            gap: 1.275rem;

            .list-one,
            .list-two,
            .list-three,
            .list-four,
            .list-five {
                .title {
                    font-size: .3rem;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    margin-bottom: .275rem;
                }

                .content {
                    display: grid;
                    grid-auto-flow: row;
                    gap: .25rem;
                    font-size: .175rem;
                    font-family: Adobe Heiti Std;
                    font-weight: normal;
                    vertical-align: middle;


                    .li-one .telephone {
                        width: .175rem;
                        height: 14px;
                        display: inline-block;
                        background: #B2B2B2;
                        background: url('./img/telephone.png') no-repeat;
                        background-size: 100% 100%;
                        margin-right: .075rem;
                        vertical-align: middle
                    }

                    .li-two .email {
                        width: .175rem;
                        height: .1375rem;
                        display: inline-block;
                        background: #B2B2B2;
                        background: url('./img/email.png') no-repeat;
                        background-size: 100% 100%;
                        margin-right: .075rem;
                        vertical-align: middle
                    }

                    .li-three .position {
                        width: .15rem;
                        height: .1875rem;
                        display: inline-block;
                        background: #B2B2B2;
                        background: url('./img/position.png') no-repeat;
                        background-size: 100% 100%;
                        margin-right: .075rem;
                        vertical-align: middle
                    }
                }

            }
        }



    }

    .content-line {
        height: .025rem;
        background-color: #FFFFFF;
        margin-bottom: .6375rem;
    }

    .footer-title {
        text-align: center;

        .flag {
            color: #4C80E7;
        }
    }
}